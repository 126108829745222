import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import { ReactComponent as ArrowDown } from 'src/assets/ArrowDown.svg';

import Text from 'src/components/Text/Text';
import ButtonsRow from './ButtonsRow';
import NoResult from 'src/components/NoResult/NoResult';
import Loader from 'src/components/Loader/Loader';
import Pagination from 'src/components/Pagination';

import { ROUTE_DASHBOARD, ROUTE_TRANSACTIONS, ROUTE_TRANSACTIONS_FIAT } from 'src/routes';
import { convertDate } from 'src/constants';

import { StyledItem, StyledRow, StyledTable } from '../styled';

interface ITable {
  title: string;
  buttons: { id: number; text: string; hasDropDown: boolean; caption?: string }[];
  tableHeaders: string[];
  columns: string;
  hasDateBtn?: boolean;
  hasSearch?: boolean;
  hasAsideBtns?: boolean;
  isTransaction?: boolean;
  transactions?: any;
  selectedType?: string;
  setSelectedType?: any;
  currentPage?: number;
  setCurrentPage?: any;
  totalPages?: number;
  loading?: boolean;
}

const Table: FC<ITable> = ({
  title,
  buttons,
  tableHeaders,
  columns,
  hasDateBtn,
  hasAsideBtns,
  isTransaction,
  transactions,
  selectedType,
  setSelectedType,
  currentPage,
  setCurrentPage,
  totalPages,
  loading,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <StyledTable $isTransaction={!!isTransaction}>
      <div
        style={{ cursor: 'pointer', marginBottom: '20px' }}
        onClick={() => navigate(ROUTE_DASHBOARD)}
      >
        <span>
          <ArrowDown height={8} style={{ transform: 'rotate(90deg)' }} />
        </span>
        <span style={{ textDecoration: 'underline', paddingLeft: '10px' }}>Back</span>
      </div>
      {!isTransaction && (
        <Text size={18} color="#1B224A" padding="0 0 1.75vh 2.5vw">
          Spot
        </Text>
      )}
      <br />
      <Text size={22} color="#1B224A" weight={600} padding="0 0 3vh 2.5vw">
        {title}
      </Text>
      {isTransaction && (
        <div className="transactions_nav_btns">
          <button
            className={pathname === ROUTE_TRANSACTIONS ? 'active' : ''}
            onClick={() => navigate(ROUTE_TRANSACTIONS)}
          >
            Crypto
          </button>
          <button
            className={
              pathname === `${ROUTE_TRANSACTIONS}${ROUTE_TRANSACTIONS_FIAT}` ? 'active' : ''
            }
            onClick={() => navigate(`${ROUTE_TRANSACTIONS}${ROUTE_TRANSACTIONS_FIAT}`)}
          >
            Fiat
          </button>
        </div>
      )}
      <ButtonsRow
        hasAsideBtns={!!hasAsideBtns}
        hasDateBtn={hasDateBtn}
        hasSearch={pathname === ROUTE_TRANSACTIONS}
        buttons={buttons}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      {loading ? (
        <Loader size={100} />
      ) : transactions && transactions.length ? (
        <>
          <div className="desktop_view" style={{ paddingBottom: '30px' }}>
            <StyledRow $columns={columns} $isHeader>
              {tableHeaders.map((header) => (
                <span key={header}>{header}</span>
              ))}
            </StyledRow>
            <StyledRow
              $columns="0.75fr 0.75fr 0.75fr 1.25fr 1fr .6fr"
              style={{ padding: '0.6vw 2vw' }}
            >
              {!!transactions &&
                transactions.map(({ created_at, currency, kind, to, from, quantity, status }) => (
                  <>
                    <span key={v4()} style={{ padding: '8px 0' }}>
                      <span>{convertDate(created_at).date}</span>
                      <br />
                      <span>{convertDate(created_at).time}</span>
                    </span>
                    <StyledItem key={v4()}>{currency}</StyledItem>
                    <StyledItem key={v4()}>{kind}</StyledItem>
                    <StyledItem key={v4()}>{to || from}</StyledItem>
                    <StyledItem key={v4()}>{quantity}</StyledItem>
                    <StyledItem key={v4()}>{status}</StyledItem>
                  </>
                ))}
            </StyledRow>
            {transactions && transactions.length ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  currentPage={currentPage as number}
                  setCurrentPage={setCurrentPage}
                  nPages={totalPages as number}
                />
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <NoResult />
      )}
      <div className="mobile_view">
        <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
          DOT/USDT
        </Text>
        <StyledRow $columns={columns} $isHeader>
          {tableHeaders.map((header) => (
            <span key={header}>{header}</span>
          ))}
        </StyledRow>
        <Text size={18} weight={700} color="#111" padding="0 0 .4vh 2vw">
          SOL/USDT
        </Text>
        <StyledRow $columns={columns} $isHeader>
          {tableHeaders.map((header) => (
            <span key={header}>{header}</span>
          ))}
        </StyledRow>
      </div>
    </StyledTable>
  );
};

export default Table;
