import React from 'react';

import NoResult from 'src/components/NoResult/NoResult';

import { convertDate } from 'src/constants';

import { StyledDataRow } from '../styled';

const DisplayItems = ({ items, columns }) => {
  return (
    <div>
      {items.length ? (
        items.map(
          ({ id, created_at, pair, direction, owner_type, executed_price, quantity, status }) => (
            <StyledDataRow key={id} $columns={columns}>
              <span>
                <span>{convertDate(created_at).date}</span>
                <br />
                <span>{convertDate(created_at).time}</span>
              </span>
              {[pair, direction, owner_type, executed_price, quantity, status].map((i) => (
                <span key={i}>{i}</span>
              ))}
              <span>{quantity}</span>
            </StyledDataRow>
          ),
        )
      ) : (
        <NoResult styles={{ padding: '10% 0 5%' }} />
      )}
    </div>
  );
};

export default DisplayItems;
