import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as User } from '../../../assets/User.svg';
import { ReactComponent as Settings } from '../../../assets/Settings.svg';
import { ReactComponent as Language } from '../../../assets/Language.svg';
import { ReactComponent as Logout } from '../../../assets/Logout.svg';
import { ReactComponent as ChangePassword } from '../../../assets/ChangePassword.svg';

import { ROUTE_CHANGE_PW, ROUTE_DASHBOARD } from 'src/routes';

import { StyledOptionsModal } from 'src/pages/Trade/components/Tabs/styled';

const OptionsModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showPasswordList, setShowPasswordList] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    const { target } = event;
    if (target instanceof Node && !modalRef.current?.contains(target)) {
      handleClose();
      setShowPasswordList(false);
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem('access');
    navigate('/');
    navigate(0);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledOptionsModal ref={modalRef} onClick={handleClose}>
      <div style={{ display: 'flex', gap: '20px', padding: '0 15px' }}>
        <User />
        <span
          style={{ color: '#111', cursor: 'pointer' }}
          onClick={() => navigate(ROUTE_DASHBOARD)}
        >
          Dashboard
        </span>
      </div>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center', padding: '0 15px' }}>
        <Language />
        <span style={{ color: '#111' }}>Language and region</span>
      </div>
      <div
        style={{ display: 'flex', gap: '20px', padding: '0 15px' }}
        onMouseEnter={() => setShowPasswordList(true)}
        onMouseLeave={() => setShowPasswordList(false)}
      >
        <Settings />
        <div style={{ position: 'relative', width: '80px' }}>
          <span className="options-modal-item" style={{ color: '#111' }}>
            Settings
          </span>
          {showPasswordList && (
            <div
              style={{
                position: 'absolute',
                left: '-270px',
                top: '0',
                padding: '0 15px',
                background: '#36332e',
                color: '#fff',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
            >
              <ul style={{ listStyle: 'none', cursor: 'pointer' }}>
                <li
                  style={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'flex-start',
                    padding: '8px 0 6px',
                  }}
                  onClick={() => {
                    navigate(ROUTE_CHANGE_PW);
                    handleClose();
                  }}
                >
                  <ChangePassword />
                  <span style={{ color: '#fff' }}>Change password</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', gap: '20px', cursor: 'pointer', padding: '0 15px' }}>
        <Logout />
        <span onClick={handleLogOut} style={{ color: '#111' }}>
          Log out
        </span>
      </div>
    </StyledOptionsModal>
  );
};

export default OptionsModal;
