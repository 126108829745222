import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from 'src/store/useDispatch';
import { setAppAlert } from 'src/store/slice/appAlert';

import Loader from 'src/components/Loader/Loader';
import VerificationWrap from 'src/components/VerificationWrap/VerificationWrap';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';

import {
  StyledButton,
  StyledInputEmail,
  StyledInputEmailWrap,
  StyledSendCode,
} from '../EnterEmail/styled';

const EnterPhone = () => {
  const [hasPhone, setHasPhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [sendCodeLabel, setSendCodeLabel] = useState('Send');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    user: { id, phone_number },
  } = useSelector(({ user }) => user);

  const isFormFilled = phone && password;

  const handleCode = () =>
    request.post(`${PUBLIC_URL}/v1/users/${id}/phone-number/verification/resend`, null).then(() => {
      if (sendCodeLabel === 'Send') {
        setSendCodeLabel('Resend');
      }
      dispatch(
        setAppAlert({
          message: 'The code has been sent to your phone number.',
          isSuccess: true,
        }),
      );
    });

  const handleSubmit = () => {
    if (!hasPhone) {
      setLoading(true);
      request
        .patch(`${PUBLIC_URL}/v1/users/${id}/phone-number/`, {
          phone_number: phone,
          password,
        })
        .then(() => {
          dispatch(
            setAppAlert({
              message: 'The code has been sent to your phone number.',
              isSuccess: true,
            }),
          );
          setHasPhone(true);
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Phone number or password is incorrect.',
              isSuccess: false,
            }),
          );
          setPhone('');
          setPassword('');
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      request
        .post(`${PUBLIC_URL}/v1/users/${id}/phone-number/verification/resend`, null)
        .then(() => {
          dispatch(
            setAppAlert({
              message: 'The code has been sent to your phone number.',
              isSuccess: true,
            }),
          );
          setHasPhone(true);
        })
        .catch(({ response }) => {
          dispatch(
            setAppAlert({
              message: response?.data?.error || 'Phone number or password is incorrect.',
              isSuccess: false,
            }),
          );
          setPhone('');
          setPassword('');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <VerificationWrap>
      <div>
        <div style={{ fontSize: 'calc(18px + 2vw)', paddingBottom: '20px' }}>
          {hasPhone ? 'Phone verification' : 'Update phone number'}
        </div>
        {hasPhone && (
          <p>
            Please enter the 6 digits verification code that was sent to {phone_number}.{' '}
            <span
              style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setHasPhone(false)}
            >
              Edit
            </span>
            <div>The code is valid for 2 minutes.</div>
          </p>
        )}
        <div style={{ paddingLeft: '20px' }}>
          <div style={{ paddingBottom: '10px' }}>
            {hasPhone && !phone && !password ? 'Phone verification code' : 'Personal phone number'}
          </div>
          <StyledInputEmailWrap>
            <StyledInputEmail
              value={hasPhone ? code : phone}
              onChange={(e) =>
                hasPhone ? setCode(e.currentTarget.value) : setPhone(e.currentTarget.value)
              }
            />
            {hasPhone && <StyledSendCode onClick={handleCode}>{sendCodeLabel} code</StyledSendCode>}
          </StyledInputEmailWrap>
          {!hasPhone && (
            <>
              <div style={{ paddingBottom: '10px' }}>Password</div>
              <StyledInputEmailWrap>
                <StyledInputEmail
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </StyledInputEmailWrap>
            </>
          )}
          <br />
          <StyledButton
            disabled={loading}
            style={{
              pointerEvents:
                (!loading && isFormFilled && !hasPhone) || code.length === 6 ? 'auto' : 'none',
              background:
                (!loading && isFormFilled && !hasPhone) || code.length === 6 ? '#00FEB9' : '#ccc',
            }}
            onClick={handleSubmit}
          >
            {loading ? <Loader size={24} /> : hasPhone ? 'Submit' : 'Update phone number'}
          </StyledButton>
        </div>
      </div>
    </VerificationWrap>
  );
};

export default EnterPhone;
