import { ReactComponent as OpenOrders } from './assets/OpenOrders.svg';
import { ReactComponent as OrderHistory } from './assets/OrderHistory.svg';
import { ReactComponent as TradeHistory } from './assets/TradeHistory.svg';
import { BsGraphUpArrow } from 'react-icons/bs';
import { PiPlusMinusBold } from 'react-icons/pi';
import { LuLayoutDashboard } from 'react-icons/lu';
import { MdBorderColor } from 'react-icons/md';
import { BsWalletFill } from 'react-icons/bs';
import { AUTH_REDIRECT_URI, AUTH_URL, CLIENT_ID } from './configs';
import { ROUTE_DASHBOARD, ROUTE_OPEN_ORDERS, ROUTE_ORDERS, ROUTE_WALLET } from './routes';

export const OPEN_ORDERS_HEADERS = [
  'Date',
  'Pair',
  'Type',
  'Owner type',
  'Price',
  'Amount',
  'Status',
  'Total',
];

export const ORDER_HISTORY_HEADERS = [
  'Order time',
  'Pair',
  'Type',
  'Side',
  'Price',
  'Executed',
  'Amount',
  'Total',
  'Status',
];

export const TRADE_HISTORY_HEADERS = [
  'Date',
  'Pair',
  'Side',
  'Price',
  'Filled',
  'Fee',
  'Role',
  'Total',
];

export const TRANSACTIONS_CRYPTO_HEADERS = [
  'Time',
  'Currency',
  'Type',
  'Wallet ID',
  'Amount',
  'Status',
];

export const TRANSACTIONS_FIAT_HEADERS = ['Title', 'Coin', 'Amount', 'Status', 'Information'];

export const OPEN_ORDERS_BTNS = [
  { text: 'Pair', label: 'All', hasDropDown: true },
  { text: 'Type', label: 'All', hasDropDown: true, gap: 13 },
];

export const ORDER_HISTORY_BTNS = [
  { text: 'Pair', label: 'All', hasDropDown: true },
  { text: 'Type', label: 'All', hasDropDown: true },
];

export const TRADE_HISTORY_BTNS = [
  { text: 'Pair', label: 'All', hasDropDown: true },
  { text: 'Type', label: 'All', hasDropDown: true },
];

export const ASIDE_LINKS = [
  { title: 'Open Orders', to: 'open-orders', src: OpenOrders },
  { title: 'Order History', to: 'order-history', src: OrderHistory },
  { title: 'Trade History', to: 'trade-history', src: TradeHistory },
];

export const TRANSACTIONS_CRYPTO_BTNS = [
  { id: 1, text: 'Deposit', hasDropDown: true, caption: 'Type' },
  { id: 2, text: 'Past 30 days', hasDropDown: true, caption: 'Time' },
  { id: 3, text: 'All', hasDropDown: true, caption: 'Asset' },
  { id: 4, text: 'All', hasDropDown: true, caption: 'Status' },
];

export const TRANSACTIONS_FIAT_BTNS = [
  { id: 1, text: 'Deposit', hasDropDown: true, caption: 'Type' },
  { id: 2, text: 'All', hasDropDown: true, caption: 'Fiat' },
  { id: 3, text: 'Past 30 days', hasDropDown: true, caption: 'Time' },
  { id: 4, text: 'All', hasDropDown: true, caption: 'Status' },
];

export const HEADERS = ['Date', 'Pair', 'Sell/Buy', 'Price', 'Amount', 'Total', 'Cancel All'];

export const convertDate = (str: string) => {
  const dateObj = new Date(str);
  const date = dateObj.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const time = dateObj.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  return { date, time };
};

export const extractCurrencies = (pairString) => {
  const pair = pairString.split('=')[1];
  const currencies = pair.split('-');
  return currencies.map((currency) => currency.toUpperCase());
};

export const getSign = () => {
  const scopes = [
    'offline_access',
    'write:user.phone_number',
    'read:user.phone_number',
    'write:user.email',
    'read:user.email',
    'read:user.profile',
    'write:user.profile',
    'write:user.password',
  ];

  const generateQs = (params: {
    client_id?: string;
    response_type: string;
    scope: string;
    redirect_uri?: string;
    prompt: string;
  }) => {
    const newParams = new URLSearchParams(params).toString();
    if (!newParams) return '';
    return '?' + newParams;
  };

  const params = {
    client_id: CLIENT_ID,
    response_type: 'code',
    scope: scopes.join(' '),
    redirect_uri: AUTH_REDIRECT_URI,
    prompt: 'login',
  };

  const qs = generateQs(params);
  const sign = AUTH_URL + '/auth' + qs;

  return sign;
};

export const getDocTypeConverted = (docType: string) => {
  if (!docType || docType === 'Select document type') {
    return '';
  }

  return docType === 'Driving license' ? 'DRIVING_LICENSE' : 'IDENTITY_CARD';
};

export const getCurrencyPairs = (pair: string) => (pair ? pair.split('/') : ['', '']);

export const getCryptoCurrencyLogo = (currencies: any, currency: string) => {
  const icon = currencies.find(({ code }) => code.toLowerCase() === currency)?.icon;
  return icon?.replace('https://static.bxlend.com/', '') ?? '';
};

export const getChange24 = (p: string | null) => (p ? parseFloat(p) : 0);

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.:#-])[A-Za-z\d@$!%*?&.:#-]{8,}$/;
  return passwordRegex.test(password);
};

export const PASSWORD_VALIDATION_ERROR = `Password must contain a capital letter, a number, and a special character (£, &, :, .,
  @) and have at least 8 characters`;

export const KYC_STATUS = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
};

export const VERIFY_ACCOUNT_MESSAGES = {
  PENDING: 'Your KYC data is under consideration.',
  VERIFIED: 'You verified your KYC data.',
  CANCELED: 'You KYC data verification failed.',
  UNVERIFIED: 'Verify your account in a few minutes.',
};

export const getColor = (status) => {
  if (!status) {
    return '#828282';
  }

  if (status === KYC_STATUS.PENDING) {
    return 'yellow';
  }

  if (status === KYC_STATUS.VERIFIED) {
    return '#20BF55';
  }

  return 'red';
};

export const SOCIAL_MEDIAS = ['Facebook', 'Linkedin', 'Twitter', 'Youtube'];

export const WALLET_BUTTON_TITLES = [
  'Deposit',
  'Withdraw',
  'Send',
  'Transfer',
  'Transaction History',
];

export const ALL_FAV_BTNS: string[] = ['All', 'Favourites'];

export const mobileNavLists = [
  {
    title: 'Market',
    link: '/market',
    Icon: BsGraphUpArrow,
  },
  {
    title: 'Trade',
    link: '/trade?pair=btc-usdt',
    Icon: PiPlusMinusBold,
  },
  {
    title: 'Dashboard',
    link: ROUTE_DASHBOARD,
    Icon: LuLayoutDashboard,
  },
  {
    title: 'Orders',
    link: `${ROUTE_ORDERS}${ROUTE_OPEN_ORDERS}`,
    Icon: MdBorderColor,
  },
  {
    title: 'Wallet',
    link: ROUTE_WALLET,
    Icon: BsWalletFill,
  },
];

export const SORT_DROPDOWN_OPTIONS = [
  { label: 'Name', filter: 'name' },
  { label: 'Price', filter: 'price' },
  { label: '24h Change', filter: 'change' },
];

export const getOrdersType = (pathname: string) => {
  if (pathname === `${ROUTE_ORDERS}/open-orders`) return 'OPEN_ORDER';
  return pathname === `${ROUTE_ORDERS}/order-history` ? 'ORDER_HISTORY' : 'TRADE_HISTORY';
};
