import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchTickers } from 'src/store/slice/tickers';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';
import { fetchCurrencyPairs } from 'src/store/slice/currencyPairs';

import Table from './components/Table';
import BreadCrumb from './components/BreadCrumb';

import './index.css';

const Market = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('access');

  const { tickers, loading } = useSelector(({ tickers }) => tickers);
  const { pairs } = useSelector((state: any) => state.pairs);

  useEffect(() => {
    if (token) {
      dispatch(fetchNotificationsCount());
    }

    if (!tickers.length) {
      dispatch(fetchTickers());
    }

    if (!pairs.length) {
      dispatch(fetchCurrencyPairs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pairs.length, tickers.length]);

  return (
    <div className="container-fluid px-lg-5 px-sm-3" style={{ minHeight: '83vh' }}>
      <BreadCrumb />
      <Table tickers={tickers} loading={loading} />
      <div style={{ height: 20 }}></div>
    </div>
  );
};

export default Market;
