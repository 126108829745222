import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchCountries } from 'src/store/slice/countries';

import { ReactComponent as ArrowDown } from 'src/assets/ArrowDown.svg';

import Content from './Content';
import CountryInput from './CountryInput';
import UploadButton from './UploadButton';
import CapturePicture from './CapturePicture';
import Checkbox from './Checkbox';

import {
  StyledCheckboxLabel,
  StyledClosebtn,
  StyledContainer,
  StyledInputContent,
  StyledSquare,
} from './styled';

const VerificationPopUp = ({
  onClose,
  firstName,
  setFirstName,
  middleName,
  setMiddleName,
  lastName,
  setLastName,
  docType,
  setDocType,
  query,
  setQuery,
  documentQuery,
  setDocumentQuery,
  address,
  setAddress,
  pinCode,
  setPinCode,
  city,
  setCity,
  termsAgreed,
  setTermsAgreed,
  privacyAgreed,
  setPrivacyAgreed,
  countries,
  uploadedImage,
  setUploadedImage,
  frontImage,
  setFrontImage,
  backImage,
  setBackImage,
  handleSubmit,
  submitLoading,
}) => {
  const [step, setStep] = useState(1);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef();

  const dispatch = useDispatch();

  const { isDark } = useSelector(({ isDark }) => isDark);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose]);

  const handleStartCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // @ts-expect-error TODO: Fix type
        videoRef.current.srcObject = stream;
        // @ts-expect-error TODO: Fix type
        videoRef.current.play();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!countries.length) {
      dispatch(fetchCountries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledContainer ref={popupRef} className={isDark ? 'isDark' : ''}>
        <div
          style={{
            display: 'flex',
            justifyContent: step > 1 ? 'space-between' : 'flex-end',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',
            paddingTop: '15px',
          }}
        >
          {step > 1 && (
            <span onClick={() => setStep(step - 1)} style={{ cursor: 'pointer' }}>
              <ArrowDown height={8} style={{ transform: 'rotate(90deg)' }} />
            </span>
          )}
          <StyledClosebtn style={{ right: 0 }} onClick={() => onClose()}>
            +
          </StyledClosebtn>
        </div>
        {step === 1 && (
          <Content
            title="Lets get you verified"
            subtitle="Select your region and follow the steps"
            buttonText="Continue"
            isNextActive={
              !!(
                countries.some(
                  (obj: { name: string }) => obj.name.toLowerCase() === query.toLowerCase(),
                ) &&
                firstName &&
                lastName
              )
            }
            handleNext={() =>
              countries.some(
                (obj: { name: string }) => obj.name.toLowerCase() === query.toLowerCase(),
              ) && setStep(2)
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div>
                <div>Country/region</div>
                <CountryInput countries={countries} query={query} setQuery={(q) => setQuery(q)} />
              </div>
              <div>
                <div>First Name</div>
                <StyledInputContent
                  value={firstName}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div>
                <div>Middle Name (optional)</div>
                <StyledInputContent
                  value={middleName}
                  onChange={(e) => setMiddleName(e.currentTarget.value)}
                />
              </div>
              <div>
                <div>Last Name</div>
                <StyledInputContent
                  value={lastName}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <p>Complete the following steps to verify your account in just few minutes.</p>
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <li>Personal information</li>
                <li>Government-issued ID</li>
                <li>Facial recognition</li>
              </ul>
            </div>
          </Content>
        )}
        {step === 2 && (
          <Content
            title="Home address"
            subtitle="Fill your current residential address."
            buttonText="Continue"
            isNextActive={!!(address && pinCode && city)}
            handleNext={() => !!(address && pinCode && city) && setStep(3)}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <div>Full Address</div>
                <StyledInputContent
                  value={address}
                  onChange={(e) => setAddress(e.currentTarget.value)}
                />
              </div>
              <div>
                <div>Pincode</div>
                <StyledInputContent
                  value={pinCode}
                  onChange={(e) => setPinCode(e.currentTarget.value)}
                />
              </div>
              <div>
                <div>City</div>
                <StyledInputContent value={city} onChange={(e) => setCity(e.currentTarget.value)} />
              </div>
            </div>
          </Content>
        )}
        {step === 3 && (
          <Content
            title="Document verification"
            buttonText="Continue"
            isNextActive={
              !!countries.some(
                (obj: { name: string }) => obj.name.toLowerCase() === documentQuery.toLowerCase(),
              ) &&
              frontImage &&
              backImage &&
              docType &&
              docType !== 'Select document type'
            }
            handleNext={() =>
              countries.some(
                (obj: { name: string }) => obj.name.toLowerCase() === documentQuery.toLowerCase(),
              ) && setStep(4)
            }
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <div>Document issuing Country/Region</div>
                <CountryInput
                  countries={countries}
                  query={documentQuery}
                  setQuery={(q) => setDocumentQuery(q)}
                />
              </div>
              <div>
                <div>
                  <div>Document type</div>
                  <select
                    style={{
                      width: '100%',
                      padding: '8px 15px',
                      borderRadius: '20px',
                      border: '1px solid #ccc',
                      marginBottom: '20px',
                    }}
                    value={docType}
                    onChange={(e) => setDocType(e.currentTarget.value)}
                  >
                    <option value="Select document type">Select document type</option>
                    <option value="Passport">Passport</option>
                    <option value="Identity card">Identity card</option>
                    <option value="Driving license">Driving license</option>
                  </select>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <UploadButton text="Front" file={frontImage} setFile={setFrontImage} />
                  <UploadButton text="Back" file={backImage} setFile={setBackImage} />
                </div>
              </div>
            </div>
          </Content>
        )}
        {step === 4 && (
          <Content
            title="Liveness check"
            subtitle="You are almost there! Center your face in the frame and follow the instructions. Make sure it's completed by yourself."
            buttonText={uploadedImage ? 'Submit' : 'I am ready'}
            isNextActive={!isVideoStarted || (!!uploadedImage && termsAgreed && privacyAgreed)}
            submitLoading={submitLoading}
            handleNext={() => {
              if (uploadedImage && termsAgreed && privacyAgreed) {
                handleSubmit();
              } else {
                handleStartCamera();
                setIsVideoStarted(!isVideoStarted);
              }
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {isVideoStarted ? (
                <CapturePicture
                  videoRef={videoRef}
                  isVideoStarted={isVideoStarted}
                  handleStartCamera={handleStartCamera}
                  setUploadedImage={setUploadedImage}
                  uploadedImage={uploadedImage}
                />
              ) : (
                <StyledSquare />
              )}
              <ul style={{ paddingBottom: '20px' }}>
                <li style={{ fontWeight: 500 }}>* Only images are allowed to be uploaded.</li>
                <li>Do not hide parts of your face with hats or glasses.</li>
                <li>Take a picture in well-lit area.</li>
              </ul>
            </div>
            <>
              <div>
                <div>
                  <div>
                    <Checkbox isChecked={termsAgreed} setIsChecked={setTermsAgreed}>
                      <StyledCheckboxLabel>
                        I agree with
                        <Link to="/term-of-use"> terms and conditions</Link>
                      </StyledCheckboxLabel>
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox isChecked={privacyAgreed} setIsChecked={setPrivacyAgreed}>
                      <StyledCheckboxLabel>
                        I agree with
                        <Link to="/privacy-policy"> privacy and policy</Link>
                      </StyledCheckboxLabel>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </>
          </Content>
        )}
      </StyledContainer>
    </>
  );
};

export default VerificationPopUp;
